import React, { useEffect, useState } from 'react'
import Card from 'components/common/card'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import useSSE from 'components/common/hooks/useSSE'
import API from 'services/api'
import useQueryParams from 'components/common/hooks/useQueryParams'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import BetaTag from 'components/common/tags/betaTag'
import { AssistantAnswerStatuses, AssistantAnswerType } from 'types/ai/assistantAnswer'
import Sources from 'components/search/ai/sources'
import Feedback from 'components/search/ai/feedback'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { ButtonSecondarySmall } from 'components/common/buttons'
import { useCreateNewChatConversationMessage } from 'components/ai/chat/ask_ai_modal/hooks/useCreateNewChatConversationMessage'
import { NewConversationOrigin } from 'components/ai/chat/ask_ai_modal/types'

const I18N = i18nPath('views.search.search_results.ai_answer')

const AiOverview = ({
  title = I18N('title'),
  className = '',
}) => {
  const [assistantAnswer, setAssistantAnswer] = useState<AssistantAnswerType | null>(null)
  const sources = assistantAnswer?.sources || []
  const [content, setContent] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [followUpQuestions, setFollowUpQuestions] = useState<any[]>([])
  const { query, experimental, skipCache } = useQueryParams()
  const { settings } = useCurrentCompany()

  useEffect(() => {
    setContent('')
    setAssistantAnswer(null)
    setIsLoading(true)
    setFollowUpQuestions([])
  }, [query])

  useSSE('/api/ai/assistant/answers', {
    options: {
      headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': API.csrfToken() },
      method: 'POST',
      payload: JSON.stringify({ query, use_experimental_ai_assistant: experimental, skip_cache: skipCache }),
    },
    dependencies: [query],
    listeners: {
      'delta-received': (event) => {
        setContent(prev => prev + event.data)
      },
      'workflow-finished-without-answer': (event) => {
        const data = JSON.parse(event.data)
        setContent(data.message)
        setIsLoading(false)
      },
      'workflow-finished': (event) => {
        const data = JSON.parse(event.data)
        const aiAssistantAnswer = denormalizedJsonApiResponse({ data }, 'aiAssistantAnswer')
        setContent(aiAssistantAnswer.answer)
        setAssistantAnswer(aiAssistantAnswer)
        setIsLoading(false)
      },
      'follow-up-questions-generated': (event) => {
        const questions = denormalizedJsonApiResponse({ data: JSON.parse(event.data) }, 'aiAssistantFollowUpQuestion')
        setFollowUpQuestions(questions)
      },
    },
    listen: !!query,
  })

  const createNewChatConversationMessage = useCreateNewChatConversationMessage()

  if (!query) {
    return null
  }

  const showFollowUpQuestions = settings.aiChatbot?.enabled && followUpQuestions.length > 0


  return (
    <Card className={classNames('AiOverview mb-4 border-color-ai overflow-hidden', className)} ignoreBorderStyle>
      <div className={classNames('p-3-5', showFollowUpQuestions ? 'border-radius-top' : 'border-radius')}>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <div className='d-flex align-items-center gap-2'>
            <CdnSvg className='svg-highlight' src='/images/illustrations/aiGradientIcon.svg' />
            <div className='font-weight-600 text-ai'>{title}</div>
          </div>
          <BetaTag />
        </div>
        {!content && isLoading && <CirclesLoadingIndicator />}
        {content && (
          <div>
            <AiAnswerContent content={content} />
            {assistantAnswer && assistantAnswer?.status !== AssistantAnswerStatuses.NotAnswered && (
              <>
                <Sources sources={sources} />
                <hr className='my-3 w-100' />
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='text-secondary text-smallest'>
                    {I18N('inaccurate_content_warning')}
                  </div>
                  <Feedback
                    assistantAnswer={assistantAnswer}
                    setAssistantAnswer={setAssistantAnswer}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {showFollowUpQuestions && (
        <div className='p-3-5 bg-highlight-color-tint-90'>
          <div className='text-smallest text-secondary mb-2'>
            <span>{I18N('ask_ai_to_help_you_further')}</span>
          </div>
          <div className='d-flex gap-2 flex-wrap'>
            {followUpQuestions.map(question => (
              <ButtonSecondarySmall
                key={question.id}
                onClick={() => createNewChatConversationMessage({
                  message: question.content,
                  origin: NewConversationOrigin.SEARCH_RESULT_PROMPT,
                  query,
                })}
                className='FollowUpQuestionsButton'
              >
                <CdnSvg src='/images/commentIcon.svg' className='FollowUpQuestionsCommentIcon d-flex mr-1' />
                <span>{question.content}</span>
              </ButtonSecondarySmall>
            ))}
          </div>
        </div>
      )}
    </Card>
  )
}


export default AiOverview
