import React from 'react'
import StatusLabel from 'components/common/status_label'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.workflows.status')

const STATUS_COLORS_MAP = {
  pending: 'yellow',
  completed: 'green',
  failed: 'red',
  in_progress: 'blue',
}

const WorkflowRunStatus = ({ status }) => (
  <StatusLabel color={STATUS_COLORS_MAP[status]}>
    {I18N(status)}
  </StatusLabel>
)

export default WorkflowRunStatus
