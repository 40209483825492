import React from 'react'
import StatusLabel from 'components/common/status_label'
import { I18NCommon } from 'utils/i18nHelpers'

const WorkflowStatus = ({ isDraft }) => (
  <StatusLabel color={isDraft ? 'gray' : 'green'}>
    {isDraft ? I18NCommon('draft') : I18NCommon('active')}
  </StatusLabel>
)

export default WorkflowStatus
